<template>
  <div class="step-content">
    <v-progress-linear
      indeterminate
      color="secondary"
      class="mt-16"
    />
  </div>
</template>

<style>

</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiClose,
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';


  export default {
    name: 'Step0',

    props: {
      step: {
        type: Object,
        default: () => {}
      },
      appURL: {
        type: String,
        default: ''
      }
    },

    components: {
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiClose,
      },
    }),

    computed: {
      toast: sync('app/toast'),
      help: sync('app/views@help'),
      help_sections: sync('help/sections'),
    },

    watch: {

    },

    methods: {
      ...services,

    },

    mounted () {
    },

    directives: {
    },

  }
</script>
